import * as React from "react";
import { Helmet } from "react-helmet";

import "../../css/privacy.css";
import { MainLayout } from "../../layouts/MainLayout";

function Terms() {
  const pageTitle = `Licensing Agreement | NativeScripting`;

  return (
    <MainLayout>
      <div className="wrapper">
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>

        <div className="privacy-container">
          <h1>Licensing Agreement</h1>

          <div className="article">
            <p>
              Important notice: Please read carefully before buying training
              Courses or accessing or downloading any content from this website.
          </p>
            <h2 id="our-terms">Our terms</h2>
            <h3 id="1-these-terms">1. These terms</h3>
            <p>
              <strong>1.1 What these terms cover.</strong> These are the terms and
              conditions on which we supply digital content/courses (“Courses”) to
              you.
          </p>
            <p>
              <strong>1.2.</strong> Please see the description of the Course on
              our website for details of the content of the available Courses.
          </p>
            <p>
              <strong>1.3. Why you should read these terms.</strong> Please read
              these terms carefully before you submit your order to us. These
              terms tell you who we are, how we will provide Courses to you, how
              you and we may change or end the contract, what to do if there is a
              problem and other important information. If you think that there is
              a mistake in these terms, please contact us to discuss.
          </p>
            <p>
              <strong>1.4.</strong> By clicking on the purchase button on the
              website you agree to these terms and conditions which will bind you
              and (if you are an employer) your employees. If you do not agree to
              these terms we will not license the Course and you must discontinue
              the licensing process.
          </p>
            <p>
              <strong>1.5. Are you a business customer or a consumer?</strong> In
              some areas, you will have different rights under these terms
              depending on whether you are a business or consumer. You are a
              consumer if:
          </p>
            <ul>
              <li>You are an individual.</li>
              <li>
                You are taking a licence of Courses from us wholly or mainly for
                your personal use (not for use in connection with your trade,
                business or profession).
            </li>
            </ul>
            <p>
              <em>
                Provisions specific to consumers only are identified as such in
                these terms.
            </em>
            </p>
            <p>
              <strong>
                1.6 If you are a business customer this is our entire agreement
                with you.
            </strong>{" "}
              If you are a business customer these terms constitute the entire
              agreement between us in relation to your licence of Courses. You
              acknowledge that you have not relied on any statement, promise,
              representation, assurance or warranty made or given by or on behalf
              of us which is not set out in these terms and that you shall have no
              claim for innocent or negligent misrepresentation based on any
              statement in this agreement.
          </p>
            <h3 id="2-definitions">2. Definitions</h3>
            <p>In these terms, the following definitions apply:</p>
            <p>
              <strong>Content:</strong> All content including training materials
              and any text information data images audio or video material in any
              medium form or provided by us to you.
          </p>
            <p>
              <strong>Digital Rights Management (DRM):</strong> The security and
              other measures for digital rights management which we may use from
              time to time.
          </p>
            <p>
              <strong>Reserved Rights:</strong> All rights in and relating to the
              Content that are not expressly granted to you including any other
              rights (whether known now or created later and whether or not in the
              contemplation of the parties at the time of your order).
          </p>
            <h3 id="3-information-about-us-and-how-to-contact-us">
              3. Information about us and how to contact us
          </h3>
            <p>
              <strong>3.1 Who we are.</strong> We are Nuvious a company registered
              in Maryland, United States.
          </p>
            <p>
              <strong>3.2 How to contact us.</strong> You can contact us by
              writing to us at support@nuvious.com or the above address.
          </p>
            <p>
              <strong>3.3 How we may contact you.</strong> If we have to contact
              you we will do so by writing to you at the email address or postal
              address you provided to us in your order.
          </p>
            <p>
              <strong>3.4 “Writing” includes emails.</strong> When we use the
              words “writing” or “written” in these terms, this includes emails.
          </p>
            <h3 id="4-our-contract-with-you">4. Our contract with you</h3>
            <p>
              <strong>4.1.</strong> (a) Courses can be booked using the booking
              form available on our website. You need to be registered with us
              before you can do this as you will be required to log in or create
              an account to complete the form.
          </p>
            <p>
              <strong>4.1.</strong> (b) When you submit a booking for an online
              Course, your submission represents an offer to us to book you on to
              the Course you select. On submission of the Course form, you will
              receive an automated summary email of your selection.
          </p>
            <p>
              <strong>4.2. How we will accept your order.</strong> Our acceptance
              of your order will take place when we email you to accept it, at
              which point a contract will come into existence between you and us.
          </p>
            <p>
              <strong>4.3. If we cannot accept your order.</strong> If we are
              unable to accept your order, we will inform you of this by email and
              will not charge you for the Course.
          </p>
            <p>
              <strong>4.4.</strong> On acceptance of your Order we will grant you
              a non-transferable, non-exclusive licence to use the Content for
              educational and personal training purposes.
          </p>
            <p>
              <strong>4.5.</strong> The licence granted to you is strictly limited
              to and you shall use the Content solely for the purposes of
              education or personal training.
          </p>
            <p>
              <strong>4.6.</strong> You may incur charges to your internet service
              provider while you are accessing and/or downloading the Course or
              training materials. Charges may also be payable to third parties for
              use of any software necessary to access and/or download the Course
              and/or training materials. You are responsible for paying these
              charges
          </p>
            <p>
              <strong>4.7.</strong> You will not grant sub-licences, in whole or
              in part, of any of the Content or of any of the rights granted to
              you.
          </p>
            <p>
              <strong>4.8.</strong> We expressly retain the Reserved Rights.
          </p>
            <p>
              <strong>4.9.</strong> Once your licence has been granted you may
              download and use the training materials for the purposes of
              completing the associated Course online only either (as agreed
              between the parties):
          </p>
            <p>
              <strong>4.10.</strong> on your own work or personal devices if the
              licence is a single user licence; or
          </p>
            <p>
              <strong>4.11.</strong> if the licence is a multiuser or network
              licence, by the number of concurrent users agreed between you and
              us.
          </p>
            <p>
              <strong>4.12.</strong> We are prohibited from supplying any Course
              or training materials to any person or entity located in a US
              embargoed territory, as provided by the US Treasury Office of
              Foreign Assets Control (OFAC) from time to time.
          </p>
            <p>
              <strong>4.13.</strong> Except as expressly set out in this licence,
              you undertake (and you undertake to procure that your employees or
              any other delegate undertaking a Course on your behalf or on your
              account so undertakes):
          </p>
            <p>
              <strong>4.14.</strong> not to copy the Content except where such
              copying is incidental or necessary for the purposes of completing
              the relevant Course;
          </p>
            <p>
              <strong>4.15.</strong> not to rent, lease, sub-licence, loan,
              translate, adapt, vary or modify the Content materials;
          </p>
            <p>
              <strong>4.16.</strong> not to alter or modify the whole or any part
              of the training materials nor permit the Content or any part of them
              to be combined with or become incorporated into any other materials;
          </p>
            <p>
              <strong>4.17.</strong> to supervise and control the use of the
              Content and ensure that they are used by your employees and
              representatives in accordance with the terms of this licence;
          </p>
            <p>
              <strong>4.18.</strong> To include our copyright notice on all entire
              and partial copies you may make of the Content on any medium
          </p>
            <p>
              <strong>4.19.</strong> Not to provide or otherwise make available
              the Content or the Course in whole or in part in any form to any
              other person.
          </p>
            <p>
              <strong>4.20.</strong> We can accept your offer by entering your
              booking onto our system and sending you an email confirming that you
              have been booked together with information on accessing the Course
              and Content.
          </p>
            <h3 id="5-your-obligations">5. Your obligations</h3>
            <p>
              <strong>5.1.</strong> You may not change, amend or develop the
              Content in any way.
          </p>
            <p>
              <strong>5.2.</strong> If you become aware of any misuse of any
              Content or any security breach that could compromise the security or
              integrity of the Content or otherwise adversely affect us, you will
              notify us immediately. We may suspend your rights to use the Content
              until the misuse or security breach is remedied.
          </p>
            <h3 id="6-drm">6. DRM</h3>
            <p>
              <strong>6.1.</strong> We reserve the right to implement Digital
              Rights Management and any other technological protection measures
              which we consider appropriate from time to time, to ensure that the
              Content is used within the scope of the rights granted to you. You
              undertake not to remove bypass circumvent or neutralise or modify
              any of the DRM and technological protection measures used for the
              Content.
          </p>
            <h3 id="7-your-rights-to-make-changes">
              7. Your rights to make changes
          </h3>
            <p>
              If you wish to make a change to the Course(s) you have ordered,
              please contact us. We will let you know if the change is possible.
              If it is possible we will let you know about any changes to the
              price of the Course(s), the Course availability or anything else
              which would be necessary as a result of your requested change and
              ask you to confirm whether you wish to go ahead with the change.
          </p>
            <h3 id="8-our-rights-to-make-changes">
              8. Our rights to make changes
          </h3>
            <p>
              <strong>8.1. Updates to digital content.</strong> We may update or
              require you to update digital content, provided that the digital
              content shall always match the description of it that we provided to
              you before you ordered it.
          </p>
            <h3 id="9-providing-the-course">9. Providing the course</h3>
            <p>
              <strong>9.1. When we will provide the Courses.</strong> During the
              order process, we will let you know when we will provide the Courses
              to you.
          </p>
            <ul>
              <li>
                <strong>
                  If the Course is a one-off licence of digital content.
              </strong>{" "}
                We will make the digital content available for viewing and/or
                download by you as soon as we accept your order, unless the order
                is subject to “preorder” status and is currently being developed.
                In this instance, you will receive an email notifying you once the
                course has been released.
            </li>
              <li>
                <strong>If the Course receives an update.</strong> All courses
                come with free lifetime updates, to which you will be notified of
                via email and automatically receive them in your account.
            </li>
            </ul>
            <p>
              <strong>
                9.2. We are not responsible for delays outside our reasonable
                control.
            </strong>{" "}
              If our supply of the Course(s) is delayed by an event outside our
              reasonable control, then we will contact you as soon as possible to
              let you know and we will take steps to minimise the effect of the
              delay. Provided we do this we will not be liable for delays caused
              by the event, but if there is a risk of substantial delay you may
              contact us to end the contract and receive a refund for any Courses
              you have paid for but not received.
          </p>
            <p>
              <strong>
                9.3. Reasons we may suspend access to Courses by you.
            </strong>{" "}
              We may have to suspend the access to a Course to:
          </p>
            <ul>
              <li>
                deal with technical problems or make minor technical changes; or
            </li>
              <li>make changes to the Course as notified by us to you.</li>
            </ul>
            <p>
              <strong>
                9.4 Your rights if we suspend the supply of Courses.
            </strong>{" "}
              We will contact you in advance to tell you we will be suspending
              supply of the Course, unless the problem is urgent or an emergency.
              If we have to suspend your access to a Course for longer than [7
              days] in any [28-day period] we will adjust the price so that you do
              not pay for Courses while your access is suspended. You may contact
              us to end the contract for a Course if we suspend it, or tell you we
              are going to suspend it, in each case for a period of more than [7
              days] and we will refund any sums you have paid in advance for any
              Course you have not taken in respect of the period after you end the
              contract.
          </p>
            <p>
              <strong>
                9.5. We may also suspend access to the Courses if you do not pay.
            </strong>{" "}
              If you do not pay us for the Courses when you are supposed to, we
              may suspend access to the Courses until you have paid us the
              outstanding amounts. We will contact you to tell you we are
              suspending your access to the Courses. We will not suspend access
              where you dispute the unpaid invoice. We will not charge you for the
              Courses during the period for which they are suspended. As well as
              suspending your access to the Courses, we can also charge you
              interest on your overdue payments.
          </p>
            <h3 id="10-your-rights-to-end-the-contract">
              10. Your rights to end the contract
          </h3>
            <p>
              <strong>10.1.</strong> Under the Consumer Contracts (Information,
              Cancellation and Additional Charges (Regulations 2013) (“Consumer
              Contracts Regulations”), if you are a consumer, you may cancel your
              purchase of the Course within a period of 14 calendar days
              (“Cancellation Period”) from the date on which our contract was
              made, subject to sub-clauses 10.7 and 10.8.
          </p>
            <p>
              <strong>10.2.</strong> You must inform us of your decision to cancel
              by using one of the following methods
          </p>
            <ul>
              <li>
                within the Cancellation Period emailing support@nuvious.com; or
            </li>
              <li>
                sending us a completed cancellation form, a template of which is
                attached to these terms.
            </li>
            </ul>
            <p>
              <strong>10.3.</strong> If you cancel the Course, you must
              permanently delete any Content you may have downloaded from us
              without undue delay and not later than 14 calendar days after you
              inform us of the cancellation.
          </p>
            <p>
              <strong>10.4.</strong> We reserve the right to withhold payment of
              all or part of your fee refund until we are satisfied that all
              Content has been permanently deleted by you.
          </p>
            <p>
              <strong>10.5.</strong> On cancellation subject to 10.7 and 10.8, you
              will be entitled to a full refund of the fees paid for the Course.
          </p>
            <p>
              <strong>10.6.</strong> Refunds will be made using the same method of
              payment as you used for the original order and will be paid within
              14 days of informing us of the cancellation. The period for refund
              will increase to 30 days if we are unable to credit a US bank
              account. If the fees have been paid by your employer the refund of
              fees will be credited to your employer’s bank account within 14 days
              of cancellation (provided a US bank account can be credited). For
              monetary returns, a request in writing to us is required to us from
              your employer.
          </p>
            <p>
              <strong>10.7.</strong> Your right to cancel and obtain any refund
              will be lost if you have given us express consent to provide the
              Course during the Cancellation Period and the Course has been fully
              provided. If express consent has been given but the Course is only
              part completed, you will be liable to pay for part of the Course
              actually completed.
          </p>
            <p>
              <strong>10.8.</strong> If the contract for the supply of digital
              content including a Course and Content, your right to cancel and
              obtain any refund will be lost if you have given us express consent
              to supply the digital content to you during the Cancellation Period
              or if you access the digital content before the expiry of the
              Cancellation Period.
          </p>
            <p>
              <strong>10.9.</strong> For further details of your rights under the
              Consumer Contracts Regulations please approach your local Citizens
              Advice Bureau.
          </p>
            <p>
              <strong>10.10.</strong> We reserve the right to use our discretion
              to determine whether to accept cancellations or make refunds in
              exceptional circumstances which fall outside the scope of this
              clause and to charge an additional fee in any such event to cover
              the administration costs incurred by us. Any such additional fees
              will be communicated to you before you make your decision.
          </p>
            <p>
              <strong>10.11.</strong> Where you have ordered Courses and you
              cancel one or more of those Courses, each Course which you cancel
              will be treated separately and the relevant cancellation terms will
              apply to each such cancellation.
          </p>
            <p>
              <strong>10.12.</strong> Except as set out in this clause, no
              cancellations will be permitted for a Course.
          </p>
            <h3 id="11-our-rights-to-end-the-contract">
              11. Our rights to end the contract
          </h3>
            <p>
              <strong>11.1. We may end the contract if you break it.</strong> We
              may end the contract for a Course at any time by writing to you if
              you do not make any payment to us when it is due.
          </p>
            <p>
              <strong>
                11.2. You must compensate us if you break the contract.
            </strong>{" "}
              If we end the contract in the situations set out in clause 11.1 we
              will refund any money you have paid in advance for Courses we have
              not provided but we may deduct or charge you reasonable compensation
              for the net costs we will incur as a result of your breaking the
              contract.
          </p>
            <h3 id="12-if-there-is-a-problem-with-the-course">
              12. If there is a problem with the course
          </h3>
            <p>
              <strong>How to tell us about problems.</strong> If you have any
              questions or complaints about a Course, please contact us at
              support@nuvious.com.
          </p>
            <h3 id="13-your-rights-in-respect-of-defective-courses-if-you-are-a-consumer">
              13. Your rights in respect of defective courses if you are a
              consumer
          </h3>
            <p>
              <strong>13.1.</strong> If you are a consumer we are under a legal
              duty to supply Courses that are in conformity with this contract.
              See the box below for a summary of your key legal rights in relation
              to the Courses. Nothing in these terms will affect your legal
              rights.
          </p>
            <p>Summary of your key legal rights as a consumer</p>
            <p>
              The terms in this licensing agreement affect your legal rights and obligations. If you
              do not agree to be bound by all of these terms, do not access
              or use the Service.
          </p>

            <ul>
              <li>
                if your digital content is faulty, you’re entitled to a
                replacement.
            </li>
              <li>
                if the fault can’t be fixed, or if it hasn’t been fixed within a
                reasonable time and without significant inconvenience, you can get
                some or all of your money back.
            </li>
              <li>
                if you can show the fault has damaged your device and we haven’t
                used reasonable care and skill, you may be entitled to a repair or
                compensation.
            </li>
            </ul>
            <h3 id="14-price-and-payment">14. Price and payment</h3>
            <p>
              <strong>14.1. Where to find the price for the Course.</strong> The
              price of each Course (which is not subject to VAT) will be the price
              indicated on the order pages when you placed your order. We take all
              reasonable care to ensure that the price of the Course advised to
              you is correct. However, we reserve the right to correct the price
              for a Course by notifying you.
          </p>
            <p>
              <strong>14.2. When you must pay and how you must pay.</strong> We
              accept payment with PayPal or Stripe. For Courses (digital content),
              you must pay for the Courses before you download them.
          </p>
            <p>
              <strong>
                14.3. Our right of set-off if you are a business customer.
            </strong>{" "}
              If you are a business customer you must pay all amounts due to us
              under these terms in full without any set-off, counterclaim,
              deduction or withholding (other than any deduction or withholding of
              tax as required by law).
          </p>
            <p>
              <strong>14.4. We can charge interest if you pay late.</strong> If
              you do not make any payment to us by the due date, we may charge
              interest to you on the overdue amount at the rate of 5% a year above
              the base lending rate of the United States Treasury from time to
              time. This interest shall accrue on a daily basis from the due date
              until the date of actual payment of the overdue amount, whether
              before or after judgment. You must pay us interest together with any
              overdue amount.
          </p>
            <p>
              <strong>14.5. What to do if you think an invoice is wrong.</strong>{" "}
              If you think a payment is wrong, please contact us promptly to let
              us know. You will not have to pay any interest until the dispute is
              resolved. Once the dispute is resolved we will charge you interest
              on correctly charged sums from the original due date.
          </p>
            <h3 id="15-updates">15. Updates</h3>
            <p>
              When you license a Course, we will make available for download
              updates to the Course Content from time to time.
          </p>
            <h3 id="16-our-responsibility-for-loss-or-damage-suffered-by-you-if-you-are-a-consumer">
              16. Our responsibility for loss or damage suffered by you if you are
              a consumer
          </h3>
            <p>
              <strong>
                16.1 We are responsible to you for foreseeable loss and damage
                caused by us.
            </strong>{" "}
              If we fail to comply with these terms, subject to paragraph 16.2 and
              16.3, we are responsible for loss or damage you suffer that is a
              foreseeable result of our breaking this contract or our failing to
              use reasonable care and skill, but we are not responsible for any
              loss or damage that is not foreseeable. Loss or damage is
              foreseeable if either it is obvious that it will happen or if, at
              the time the contract was made, both we and you knew it might
              happen.
          </p>
            <p>
              <strong>16.2.</strong> Subject to paragraph 16.3:
          </p>
            <ul>
              <li>
                we shall not be liable to you, whether in contract, tort
                (including negligence), breach of statutory duty, or otherwise,
                for: (i) loss of income; (ii) loss of business profits or
                contracts; (iii) business interruption; (iv)loss of anticipated
                savings; (v) loss of information; (vi)loss of goodwill or
                reputation; (vii) loss of or damage to or corruption of data or
                (viii) any indirect or consequential loss or damage of any kind,
                however so arising and whether caused by breach of contract, tort
                (including negligence) or otherwise.
            </li>
              <li>
                our total liability to you for all other losses arising under or
                in connection with any contract between us, whether in contract,
                tort (including negligence), breach of statutory duty, or
                otherwise, shall be limited to the total sums paid by you for
                Courses under such contracts in the previous 12 months.
            </li>
            </ul>
            <p>
              <strong>
                16.3. We do not exclude or limit in any way our liability to you
                where it would be unlawful to do so.
            </strong>{" "}
              This includes liability for death or personal injury caused by our
              negligence; for fraud or fraudulent misrepresentation; for breach of
              your legal rights in relation to the Courses as summarised at clause
              13.1.
          </p>
            <p>
              <strong>
                16.4. When we are liable for damage caused by defective digital
                content.
            </strong>{" "}
              If defective digital content which we have supplied damages a device
              or digital content belonging to you and this is caused by our
              failure to use reasonable care and skill, we will either repair the
              damage or pay you compensation, subject to the limit in paragraph
              16.2. However, we will not be liable for damage which you could have
              avoided by following our advice to apply an update offered to you
              free of charge or for damage which was caused by you failing to
              correctly follow installation instructions or to have in place the
              minimum system requirements advised by us.
          </p>
            <p>
              <strong>
                16.5. We are not liable for business losses. If you are a consumer
                we only supply the Courses for to you for personal training and
                education/domestic and private use.
            </strong>{" "}
              If you use the Courses for any commercial or business purpose, our
              liability to you will be limited as set out in clause 17.
          </p>
            <h3 id="17-our-responsibility-for-loss-or-damage-suffered-by-you-if-you-are-a-business">
              17. Our responsibility for loss or damage suffered by you if you are
              a business
          </h3>
            <p>
              <strong>17.1.</strong> Nothing in these terms shall limit or exclude
              our liability for:
          </p>
            <ul>
              <li>
                death or personal injury caused by our negligence, or the
                negligence of our employees;
            </li>
              <li>fraud or fraudulent misrepresentation; or</li>
              <li>
                breach of the terms implied by section 2 of the Supply of Goods
                and Services Act 1982; or
            </li>
              <li>
                any matter in respect of which it would be unlawful for us to
                exclude or restrict liability.
            </li>
            </ul>
            <p>
              <strong>17.2.</strong> Subject to paragraph 17.1, all terms implied
              by sections 3 to 5 of the Supply of Goods and Services Act 1982 are
              excluded.
          </p>
            <p>
              <strong>17.3.</strong> Subject to paragraph 17.1:
          </p>
            <ul>
              <li>
                we shall not be liable to you, whether in contract, tort
                (including negligence), breach of statutory duty, or otherwise,
                for: (i) loss of income; (ii) loss of business profits or
                contracts; (iii) business interruption; (iv)loss of anticipated
                savings; (v) loss of information; (vi)loss of goodwill or
                reputation; (vii) loss of or damage to or corruption of data or
                (viii) any indirect or consequential loss or damage of any kind,
                however so arising and whether caused by breach of contract, tort
                (including negligence) or otherwise.
            </li>
              <li>
                our total liability to you for all other losses arising under or
                in connection with any contract between us, whether in contract,
                tort (including negligence), breach of statutory duty, or
                otherwise, shall be limited to 200% (two hundred per cent) of the
                total sums paid by you for Courses under such contracts in the
                previous 12 months.
            </li>
            </ul>
            <p>
              <strong>17.4.</strong> These terms set out the full extent of our
              obligations and liabilities in respect of the supply of the Course
              and the Content. In particular, there are no conditions warranties
              representations or other terms expressed or implied that will be
              binding on us, except as specifically set out in this licence.
          </p>
            <h3 id="18-data-protection-and-our-use-of-your-personal-information">
              18. Data protection and our use of your personal information
          </h3>
            <p>
              <strong>18.1</strong> We will process the information we receive
              from you or otherwise hold about you in accordance with these terms
              and our privacy policy. You consent to
              our use of such information in accordance with these terms and our
              privacy policy.
          </p>
            <p>
              <strong>18.2.</strong> We will use the personal information you
              provide to us:
          </p>
            <ul>
              <li>to supply the Courses to you;</li>
              <li>to process your payment for the Courses; and</li>
              <li>
                if you agreed to this during the order process, to give you
                information about similar Courses that we provide, but you may
                stop receiving this at any time by contacting us.
            </li>
            </ul>
            <p>
              <strong>18.3.</strong> We will only give your personal information
              to other third parties where the law either requires or allows us to
              do so.
          </p>

            <p>
              <strong>18.4.</strong> You hereby grant us the nonexclusive right and license to use and display the logo and similar indicia (“Customer Marks”) derrived from the information you have provided to us under these terms, including your email address
          </p>
            <ul>
              <li>to the extent any customization or implementation of the Services involve the incorporation of Customer Marks in furtherance of these terms</li>
              <li>
                on its website and marketing collateral identifying Customer as a customer of NativeScripting or NativeScript Training. We obtain no rights in the Customer Marks except for the limited right described in the preceding sentence, and Customer retains all right, title and interest in the Customer Marks. All use of the Customer Marks by us will inure to Customer.
            </li>
            </ul>

            <h3 id="19-ip-rights">19. IP Rights</h3>
            <p>
              <strong>19.1.</strong> You acknowledge that all intellectual
              property rights in the Course and the Content anywhere in the world
              belong to us and our licensors, that rights in the Course and the
              Content are licensed (and not sold) to you and that you have no
              rights in or to the Content or Course other than the right to use
              them in accordance with the terms of this licence.
          </p>
            <p>
              <strong>19.2.</strong> None of our Content may be copied,
              reproduced, uploaded, posted, displayed or linked to in any way or
              in whole or in part without our prior permission and you must not
              allow anyone else to access our website via your login details. Any
              such use is strictly prohibited and would constitute an infringement
              of our intellectual property rights.
          </p>
            <p>
              <strong>19.3.</strong> If you take out a single user licence for a Course and
                Content but allow or permit any part of them to be accessed or
                used by other users, this will be an infringement of our and our
                licensors’ intellectual property rights. We are able to track and
                monitor access to the Course and Content to establish if multiple
                users are using a single licence (and you hereby authorise us to
                use such technology). If such multiple use is established, we will
                notify you and, unless you agree to upgrade to and pay for a
                multiple user licence, your access to the Course and Content will
                be suspended permanently and you will not be permitted to license
                other Courses, without any liability on our part. We and our
                licensors also reserve the right to pursue all available remedies
                against you for intellectual property right infringement.

            </p>
            <h3 id="20-other-important-terms">20. Other important terms</h3>
            <p>
              <strong>20.1. Defects.</strong> We do not warrant that the provision
              of any Course or Content will always be available or be
              uninterrupted, timely or error free, that defects will be corrected
              or that such Content is secure or free from bugs, viruses, errors or
              omissions. We do not make any commitment that the Course or Content
              will be compatible with or operate with your software or hardware.
          </p>
            <p>
              <strong>
                20.2. We may transfer this agreement to someone else.
            </strong>{" "}
              We may transfer our rights and obligations under these terms to
              another organisation. We will always tell you in writing if this
              happens and we will ensure that the transfer will not affect your
              rights under the contract
          </p>
            <p>
              <strong>
                20.3. You need our consent to transfer your rights to someone
                else.
            </strong>{" "}
              You may only transfer your rights or your obligations under these
              terms to another person if we agree to this in writing.
          </p>
            <p>
              <strong>
                20.4. Nobody else has any rights under this contract (except
                someone you pass your guarantee on to).
            </strong>{" "}
              This contract is between you and us. No other person shall have any
              rights to enforce any of its terms.
          </p>
            <p>
              <strong>
                20.5. If a court finds part of this contract illegal, the rest
                will continue in force.
            </strong>{" "}
              Each of the paragraphs of these terms operates separately. If any
              court or relevant authority decides that any of them are unlawful,
              the remaining paragraphs will remain in full force and effect.
          </p>
            <p>
              <strong>
                20.6. Even if we delay in enforcing this contract, we can still
                enforce it later.
            </strong>{" "}
              If we do not insist immediately that you do anything you are
              required to do under these terms, or if we delay in taking steps
              against you in respect of your breaking this contract, that will not
              mean that you do not have to do those things and it will not prevent
              us taking steps against you at a later date.
          </p>
            <p>
              <strong>
                20.7. Which laws apply to this contract and where you may bring
                legal proceedings if you are a consumer.
            </strong>{" "}
              These terms are governed by United States law and you can bring
              legal proceedings in respect of the Courses in the United States
              courts.
          </p>
            <p>
              <strong>
                20.8. Which laws apply to this contract and where you may bring
                legal proceedings if you are a business.
            </strong>{" "}
              If you are a business, any dispute or claim arising out of or in
              connection with a contract between us or its subject matter or
              formation (including non-contractual disputes or claims) shall be
              governed by and construed in accordance with the law of Maryland and
              courts of United States shall have exclusive jurisdiction to settle
              any such dispute or claim.
          </p>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default Terms;
